// Searpent blue color
$searpent-blue: #35A8FF !default;

// overwrite primary to searpent-blue color
$primary: $searpent-blue !default;

.bg-searpent-blue {
  background-color: $searpent-blue !important;
}

.gallery-thumbnail-image {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #35A8FF;
  overflow: hidden;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
}

.gallery-thumbnail-image--active {
  box-shadow: 0 0 2rem 0 #8898aa !important;
}

.gallery-thumbnail-image:hover {
  box-shadow: 0 0 2rem 0 #8898aa !important;
}

.gallery-thumbnail-image div {
  width: 100px;
  height: 100px;
}

.gallery-thumbnail-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform .2s;
}

.gallery-thumbnail-image img:hover {
  transform: scale(1.2)
}

.breadcrumb {
  display: flex;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  list-style: none;
  flex-wrap: wrap
}

.breadcrumb-item {
  padding-left: .5rem;
  text-transform: uppercase;
}

.breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  // content: '-';
  color: #8898aa
}

.breadcrumb-item:hover::before {
  text-decoration: underline
}

.breadcrumb-item:hover::before {
  text-decoration: none
}

.breadcrumb-item.active {
  color: #8898aa
}

.breadcrumb-links {
  margin: 0;
  padding: 0;
  background: 0 0
}

.breadcrumb-dark {
  background-color: transparent !important
}

.breadcrumb-dark .breadcrumb-item {
  font-weight: 600
}

.breadcrumb-dark .breadcrumb-item a {
  color: #f6f9fc
}

.breadcrumb-dark .breadcrumb-item a:hover {
  color: #fff
}

.breadcrumb-dark .breadcrumb-item+.breadcrumb-item::before {
  color: #adb5bd
}

.breadcrumb-dark .breadcrumb-item.active {
  color: #dee2e6
}

.public_layout_logo {
  max-height: 1rem;
}

.table_row_thumbnail {
  max-height: 3rem;
}

.dimmer {
  background-color: rgba(0, 0, 0, .7);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 1rem;
}

.dimmer__loader {
  background-color: rgba(255, 255, 255, .9);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
  padding: 1rem;
}

.dimmer__loader--dark {
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
  padding: 1rem;
}

.dimmer__loader__content {
  opacity: .7;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-white {
  color: white !important;
}

.zoom-in {
  cursor: zoom-in !important;
}

.zoom-out {
  cursor: zoom-out !important;
}

$dimmer-effect-speed: .2s;

.fade-in {
  animation: fadeIn ease $dimmer-effect-speed;
  -webkit-animation: fadeIn ease $dimmer-effect-speed;
  -moz-animation: fadeIn ease $dimmer-effect-speed;
  -o-animation: fadeIn ease $dimmer-effect-speed;
  -ms-animation: fadeIn ease $dimmer-effect-speed;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
}

.fadeis-in {
  animation: fadein 2s;
}

.fade-in-on-hover {
  opacity: 0.6;
}

.fade-in-on-hover:hover {
  opacity: 1;
}

@keyframes flickerAnimation {
  0% {
    opacity: 0.50;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.50;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 0.50;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.50;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 0.50;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.50;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 0.50;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.50;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
}

.isActive {
  color: #2dce89 !important;
}

.notActive {
  color: #8898aa !important;
}

.filterCheckboxGroup {
  display: grid;
  padding-left: 2rem;
}

.case-detail-model-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.image-thumbnail-title {
  pointer-events: none;
  position: absolute;
  bottom: 3px;
}

.badge-danger {
  background-color: rgba(251, 175, 190, 1) !important;
  z-index: 100;
}

.badge-success {
  background-color: rgba(147, 231, 195, 1) !important;
  z-index: 100;
}

.badge-default {
  background-color: rgba(200, 200, 195, 1) !important;
  z-index: 100;
}

.disabled-link {
  pointer-events: none;
}

.max-width-40 {
  max-width: 40%;
}

.card__collapse__toggle {
  font-size: 1rem;
  color: $searpent-blue;
  font-weight: normal;
}

.card__collapse__toggle:hover {
  font-weight: bold;
  cursor: pointer;
}

.progress--width-35px {
  max-width: 35px !important;
}

.dropzone {
  width: 80%;
  margin: 1rem auto 1em auto;
  border-radius: 0.375rem;
  border: 1px dashed #e6ecf1;
  text-align: center;
  padding: 1rem;
}

.dropzone:hover {
  border: 1px dashed #B8BCC0;
}

.dropzone--disabled {
  pointer-events: none;
}

.fullscreen-placeholder {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fullscreen-placeholder--loader {
  width: 100%;
  min-height: 35vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-control-feedback {
  color: red;
  font-size: .75rem;
  font-weight: 400;
}

.nav-link.disabled {
  font-size: 0.65rem !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #8898aa;
  opacity: 1 !important;
}

.modal-navigation {
  z-index: 1000000;
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-navigation__left {
  padding-left: 1rem;
}

.modal-navigation__right {
  padding-right: .5rem;
}

.modal-navigation__chevron {
  font-size: 2rem;
  cursor: pointer;
}

.modal-navigation__chevron:hover {
  color: $searpent-blue;
}

.modal-navigation__chevron--disabled {
  display: none;
}

.max-width-90vw {
  max-width: 90vw !important;
}

.max-height-80vh {
  max-width: 80vh !important;
}

.public-image-wrapper {
  background-color: black;
  height: 100vh;
}

.public-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-with-highlighted-area__hovered-area {
  z-index: 1000000;
  pointer-events: none;
}

.zi-5 {
  z-index: 5 !important;
}

// annotation tool fonts
@import url("https://rsms.me/inter/inter.css");

.annotation-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.annotate__pages-pane {
  background-color: rgb(255, 255, 255);
  width: 5%;
}

.annotate__annotation-pane {
  // background-color: bisque;
  width: 60%;
}

.annotate__editor-pane {
  background-color: rgb(255, 255, 255);
  width: 35%;
  overflow-y: scroll;
}

// annotation tool CSS fixes
.div-root-3 .div-root-4 {
  height: 4rem !important;
}

.page-list {
  list-style: none;
  padding: 1rem;
}

.page-thumbnail {
  margin-bottom: 1rem;
  border-radius: .5rem !important;
  overflow: hidden;
  filter: grayscale(1);
  transition: transform .2s;
  opacity: .5;
}

.page-thumbnail:hover {
  box-shadow: 0 0 2rem 0 #8898aa !important;
  filter: grayscale(0);
  cursor: pointer;
  opacity: 1;
}

.page-thumbnail-is-active {
  filter: grayscale(0);
  opacity: 1;
}

.page-thumbnail img {
  width: 100%;
}

.cdx-settings-button {
  // display: block !important;
  width: 100% !important;
}

.ce-block {
  margin-bottom: 1rem !important;
}

.editor-text-red {
  color: red !important;
}

.ce-block:hover {
  background-color: rgba(0, 0, 0, .025) !important;
}

.ce-settings__button {
  padding: 0.5rem !important;
}

.div-root-5 .div-root-6 {
  display: none !important;
}

.annotate__editor-pane__skeleton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.annotate__editor-pane__skeleton h1 {
  color: #adb5bd;
}

.makeStyles-expandedContent-63 {
  max-height: 100% !important;
}

.annotate__pages-pane__update {
  // background-color: #2dce89 !important;
  text-align: center;
  padding: 1rem;
}

.bottom-buttons button:first-child {
  background-color: transparent;
  border-radius: 0.5rem;
  background-image: linear-gradient(310deg, #627594, #a8b8d8);
  color: white;
  border: none;
}

.bottom-buttons button:last-child {
  color: white;
  background-image: linear-gradient(310deg, #2dce89, #2dcecc);
  border-radius: 0.5rem;
  border: none;
}

.div-root-10 {
  height: max-content !important;
}

.btn-dashboard-header {
  margin-bottom: 0.5rem;
}

.ce-popover--opened {
  max-height: fit-content !important;
}